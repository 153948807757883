import axios, { reLogin } from '../axios'
/**
 * 查
 * @param {object} params
 * @param {number} pn
 * @param {number} ps
 * @param {string} keyword
 */
async function dietList (params) {
  if (!params) return null
  try {
    const { data } = await axios.get('/diet/list', { params })
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
/**
 * 添加
 * @param {object} params
 * @param {string} content
 * @param {string} files
 * @param {string} title
 * @param {string} author
 */
async function dietCreate (params) {
  if (!params) return null
  try {
    const { data } = await axios.post('/diet/create', params)
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
/**
 * 删除
 * @param {number} id
 */
async function dietDelete (id) {
  if (!id) return null
  try {
    const { data } = await axios.delete(`/diet/${id}`)
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
/**
 * 改
 * @param {object} params
 * @param {string} content
 * @param {string} files
 * @param {string} id
 * @param {string} title
 * @param {string} author
 */
async function dietPut (params) {
  if (!params) return null
  try {
    const { data } = await axios.put('/diet/put', params)
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
export {
  dietCreate,
  dietList,
  dietDelete,
  dietPut
}
